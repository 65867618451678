import { Col, Container, Row } from "react-bootstrap";
import LeftSection from "../Components/LeftSection/LeftSection";
import RigntCards from "../Components/RightFourCards/RightFourCards";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Manzil Center </title>
        <meta
          name="description"
          content="Manzil gives a professional learning environment for persons or students with disability to nurture their potential & develop the requisite self-help, social, educational & vocational skills that are required to function in society."
        />
        <meta name="keywords" content="Manzil center, Special Family Support, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />
        <link rel="canonical" href="https://www.manzil.ae" />
        <meta
          property="og:title"
          content="Manzil Center - Special Families Support | Sharjah"
        />
        <meta
          property="og:description"
          content="Manzil gives a professional learning environment for persons or students with disability to nurture their potential & develop the requisite self-help, social, educational & vocational skills that are required to function in society."
        />
        <meta property="og:url" content="https://www.manzil.ae" />
        <meta
          property="og:image"
          content="https://www.manzil.ae/static/media/manzil-banner.9f1104f852c3b95ac8c3.png"
        />
        <meta
          name="twitter:title"
          content="Manzil Center - Special Families Support | Sharjah"
        />
        <meta
          name="twitter:description"
          content="Manzil gives a professional learning environment for persons or students with disability to nurture their potential & develop the requisite self-help, social, educational & vocational skills that are required to function in society."
        />
        <meta
          name="twitter:image"
          content="https://www.manzil.ae/static/media/manzil-banner.9f1104f852c3b95ac8c3.png"
        />
      </Helmet>
      <Container fluid>
        <Row>
          <Col lg={6} className="p-0">
            <LeftSection />
          </Col>
          <Col lg={6} className="p-0">
            <RigntCards />
          </Col>
        </Row>
      </Container>
    </>
  );
}
