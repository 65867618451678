import { Col, Row } from "react-bootstrap";
import "./RightFourCards.css";
import { AiOutlineRight } from "react-icons/ai";
export default function RigntCards() {
  return (
    <>
      <Row className="p-0 w-100 h-100 right-cards p-0 gx-0">
        <Col md={6} className="right-card">
          <a href="/child-skills-development">
            <div className="rc-content">
              <h2>
                Child Skills <br /> Development
              </h2>
              <p>
                {" "}
                A holistic approach primarily using art which focusses on EQ,
                self-help, cognitive & social skill development
              </p>
              <div className="text-end w-100">
                <a href="/child-skills-development">
                  <AiOutlineRight />
                </a>
              </div>
            </div>
          </a>
        </Col>
        <Col md={6} className="right-card">
          <a href="/occupational-skills">
            <div className="rc-content">
              <h2>
                Occupational Skills
                <br /> Training{" "}
              </h2>
              <p>
                {" "}
                Up-skilling & training mainly entry level corporate candidates
                across different sectors to be job-ready both on technical as
                well as soft skills
              </p>
              <div className="text-end w-100">
                <a href="/occupational-skills">
                  <AiOutlineRight />
                </a>
              </div>
            </div>
          </a>
        </Col>
        <Col md={6} className="right-card">
          <a href="/technology-innovation">
            <div className="rc-content">
              <h2>Technology & Innovation</h2>
              <p>
                {" "}
                We are delving deeper into using AI to foster DEI rich
                workplaces. We also use innovative AR & VR technologies for
                on-the-job training
              </p>
              <div className="text-end w-100">
                <a href="/technology-innovation">
                  <AiOutlineRight />
                </a>
              </div>
            </div>
          </a>
        </Col>
        <Col md={6} className="right-card">
          <a href="/consultancy">
            <div className="rc-content">
              <h2>
                Consultancy <br />
                Services{" "}
              </h2>
              <p>
                {" "}
                DEI assessment, policy formulation, training & facilitation of a
                more inclusive workplace are our core consultancy pillars
              </p>
              <div className="text-end w-100">
                <a href="/consultancy">
                  <AiOutlineRight />
                </a>
              </div>
            </div>
          </a>
        </Col>
      </Row>
    </>
  );
}
