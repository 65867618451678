import { Helmet } from "react-helmet";
import ContactSection from "../Components/ContactUs/ContactDemo";

export default function Contact() {
    return(
        <>
        <Helmet>
        <title>
        Contact Us | Manzil Center - Special Families Support | Sharjah
        </title>
        <meta
          name="description"
          content="Manzil Dubai (Occupational Skills) Office 505, Nastaran Building, Jadaf Waterfront, Dubai +9714 529 4037, Email: management@manzil.ae"
        />
        <meta name="keywords" content="Manzil center, Special Family Support, Contact for more details, consultancy, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />
        <link rel="canonical" href="https://www.manzil.ae/contact-us" />
      <meta property="og:title" content="Contact Us | Manzil Center - Special Families Support | Sharjah" />
        <meta property="og:description" content="Manzil Dubai (Occupational Skills) Office 505, Nastaran Building, Jadaf Waterfront, Dubai +9714 529 4037, Email: management@manzil.ae" />
        <meta property="og:url" content="https://www.manzil.ae/contact-us" />
        <meta property="og:image" content="https://www.manzil.ae/static/media/contact-banner.598722968091d2377765.jpg" />
        <meta name="twitter:title" content="Contact Us | Manzil Center - Special Families Support | Sharjah" />
        <meta name="twitter:description" content="Manzil Dubai (Occupational Skills) Office 505, Nastaran Building, Jadaf Waterfront, Dubai +9714 529 4037, Email: management@manzil.ae" />
        <meta name="twitter:image" content="https://www.manzil.ae/static/media/contact-banner.598722968091d2377765.jpg" />
        
      </Helmet>
      <ContactSection />
        </>
    )
}