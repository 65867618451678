import { Col, Container, Row } from "react-bootstrap";
import "./OurStory.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import un from "../../assets/awards/un.jpeg";
import arabian from "../../assets/awards/arabian.jpeg";
import Outstanding from "../../assets/awards/Outstanding-Director-Award-2012---PHASE-AWARDS.jpg";
import OutstandingSpecial from "../../assets/awards/Outstanding-Special-Needs-Supporter-2010-&-2015-(Zurich)---PHASE-AWARDS.jpg";
import achievement from "../../assets/awards/achievement2.jpg";
import PearsonAssured from "../../assets/awards/Pearson-Assured-Blue.jpg";
import committedSmall from "../../assets/awards/committed_small.png";
import journy from "../../assets/img/our-journy.jpg";

import ReadMoreReact from "read-more-react";
import ReactReadMoreReadLess from "react-read-more-read-less";
export default function OurStory() {
  return (
    <>
      <Container className="mt-4 pt-3">
        <Row>
          {/* <Col lg={12}></Col> */}
          <Col lg={6}>
            <div class="story-image h-100">
              <img src={journy} className="img-fluid h-100 w-100" alt="Our Journey | Manzil" />
            </div>
          </Col>
          <Col lg={6} className="story-content">
            <div className="main-heading mb-2">
              <span>Our Journey</span>
            </div>
            <p>
              The genesis of Manzil was a non-profit support group called
              ‘Special Families Support’ which started on 9th December, 1999. The group grew to over 200
              members and is one of GCC’s oldest non-profit groups focusing on
              social inclusion of people of determination.
            </p>
            <p>
              The support group blossomed into “Manzil”, a Center that was
              officially inaugurated on February 7th 2005 by Sheikha Jameela
              Mohammed Al Qasimi, Director General, Sharjah City for
              Humanitarian Services.
            </p>
            <p>
              Meaning “Destination” in Urdu or “Home” in Arabic, Manzil
              initially focussed on students with cognitive disabilities. In
              2022 Manzil expanded both its vision and its premises. The
              ideology of the Center is no longer limited to people of
              determination. The Manzil Centers now operating from both Sharjah
              and Dubai are aimed at ALL learners.
            </p>
            <p>
              The Manzil Occupational skills programme is ratified by KHDA in
              Dubai and Child skills development by SPEA in Sharjah
            </p>
            <p>
              The scope of services has also broadened to advisory and training
              around DEI - Diversity, Equity & Inclusion.{" "}
            </p>
            <p>
              Manzil has proudly worked with over 275+ companies, 30+ Educational Institutions and
              many Government agencies to foster this change mandate.
            </p>
          </Col>
          <Col lg={12} className="text-center my-2 mb-lg-4  mt-lg-5 pt-lg-3">
            <div className="main-heading mt-2 text-center">
              <span className="center">Awards</span>
            </div>

            <Swiper
              slidesPerView={1}
              loop={true}
              spaceBetween={10}
              pagination={false}
              navigation={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: true,
              // }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Navigation, Pagination]}
              className="mySwiper pt-3 award-slider">
              <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={un}
                      className="img-fluid"
                      alt="United Nations Zero Project Award"
                    />
                  </div>
                  <div className="award-content">
                    <h5>United Nations Zero Project Award</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`United Nations Zero Project Top 10 Global Award Winner,
                      2020. Felicitated at the UN HQ in Vienna`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={arabian}
                      className="img-fluid"
                      alt="Arabian Business Achievement Award"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Arabian Business Achievement Award</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`Manzil won the Outstanding Social Service Provider Award
                      for the Middle East`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={Outstanding}
                      className="img-fluid"
                      alt="Outstanding Director Award"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Outstanding Director Award</h5>
                    <ReactReadMoreReadLess
                      charLimit={80}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`Princess Haya Outstanding Director General Award (2012)
                      competing against 200+ global leaders`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={OutstandingSpecial}
                      className="img-fluid"
                      alt="Outstanding Special Needs Supporter"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Outstanding Special Needs Supporter</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`
                      Outstanding Special Needs Supporter 2010 & 2015 (Zurich &
                      Jumeirah Creekside Hotel) - PHASE AWARDS`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={achievement}
                      className="img-fluid"
                      alt="Manzil Graduates"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Manzil Graduates</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`85% of Manzil graduates are in mainstream jobs in the UAE
                      spanning 10 different sectors with a high (pre-Covid)
                      retention rate of 95%!`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>

              {/* <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src="https://www.manzil.ae/assets/img/achievements/Outstanding-Family-of-Special-Needs-Child-2010---PHASE-AWARDS.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Outstanding Family</h5>
                    <p>
                      Outstanding Family of Special Needs Child 2010 - PHASE
                      AWARDS. Nelofer is one of our first graduates and is
                      working at ENBD to support her family.
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}

              {/* <SwiperSlide>
                {" "}
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src="https://www.manzil.ae/assets/img/achievements/achievements5.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Manzil Art Collection</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`Manzil Art Collection displayed in prestigious art studio
                      and hotels in Dubai`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide> */}
              {/* <SwiperSlide>
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src="https://www.manzil.ae/assets/img/achievements/achievements6.jpg"
                      className="img-fluid"
                    />
                  </div>
                  <div className="award-content">
                    <h5>partial integration program</h5>
                    <p>
                      Students are attending the partial integration program
                      regularly to interact with their mainstream peers.
                    </p>
                  </div>
                </div>
              </SwiperSlide> */}

              <SwiperSlide>
                {" "}
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={PearsonAssured}
                      className="img-fluid "
                      alt="Special Education Center"
                    />
                  </div>
                  <div className="award-content">
                    <h5>Special Education Center</h5>
                    {/* <p>
                      Manzil is the only Pearson Assured Special Education
                      Center in the GCC region. The Center has an international
                      benchmark on quality of all the processes underpinning the
                      design, delivery, quality assurance and/or assessment of
                      the education and training programs.
                    </p> */}
                    {/* <ReadMoreReact text={`Manzil is the only Pearson Assured Special Education
                      Center in the GCC region. The Center has an international
                      benchmark on quality of all the processes underpinning the
                      design, delivery, quality assurance and/or assessment of
                      the education and training programs.`} /> */}

                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`Manzil is the only Pearson Assured Special Education
                      Center in the GCC region. The Center has an international
                      benchmark on quality of all the processes underpinning the
                      design, delivery, quality assurance and/or assessment of
                      the education and training programs.`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div className="award-card">
                  <div className="award-img">
                    <img
                      src={committedSmall}
                      className="img-fluid "
                      alt="UK Disability Confident Scheme"
                    />
                  </div>
                  <div className="award-content">
                    <h5>UK Disability Confident Scheme</h5>
                    <ReactReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read more"}
                      readLessText={"Show less"}>
                      {`Manzil is the first and only organization certified as
                      Disability Confident Committed outside UK, in line with
                      the UK Disability Confident Accreditation Scheme`}
                    </ReactReadMoreReadLess>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>

          <Col lg={12}>
            <div className="main-heading mt-2 text-center">
              <span className="center">Testimonials</span>
            </div>
            <Swiper
              slidesPerView={1}
              loop={true}
              spaceBetween={10}
              pagination={false}
              navigation={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: true,
              // }}
              modules={[Navigation, Pagination]}
              className="mySwiper pt-3">
              <SwiperSlide>
                <div className="text-center px-lg-5">
                  <p>
                    Our collaboration began in 2007 and continues to be very
                    valuable to us. During our interaction, my team and I were
                    impressed by your professionalism and commitment and that of
                    your team at Manzil. Despite the limited resources, it was
                    particularly encouraging to see that you and the other staff
                    members were knowledgeable, had an active in–house training
                    program and were very receptive to the research ideas
                    presented by our team.
                  </p>
                  <span class="text-orange">
                    Dr. Christopher A. Walsh MD, PhD, Bullard Professor Of
                    Neurology and Paediatrics, Harvard Medical School
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center px-lg-5">
                  <p>
                    I have personally interacted with the parents of some of the
                    students and was touched by the joy I saw in the eyes of the
                    parents at seeing their children step up with pride to find
                    their place in mainstream society. Financial transparency
                    has always been a focus in this organisation, and I am happy
                    to state that Manzil has always had a very good history in
                    this regard.
                  </p>
                  <span class="text-orange">
                    Edward B Quinlan, Former UAE Country Managing Partner, Ernst
                    & Young
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center px-lg-5">
                  <p>
                    I would like to extend my appreciation for your cooperation
                    with our team in the sharing of information and suggestions
                    for the development of the Centre for Excellence in
                    Disabilities. Your openness and willingness to work together
                    proved to be of great value to the team, and provided us
                    with a clear insight into the current situation with regards
                    to special needs in the UAE.
                  </p>
                  <span class="text-orange">
                    Dr Abdulla Al Karam, Director General, KHDA and former
                    Secretary General, Dubai Education Council
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center px-lg-5">
                  <p>
                    The Director Dr. Ayesha S. Husaini, the senior management
                    and staff of Manzil care about their student’s holistic
                    development and have been eager and extremely supportive in
                    participating in our pilot projects with best buddies since
                    March, 2008 and Partial Integration since October, 2008.
                    These are two programs many of us at The Millennium School,
                    Dubai are extremely proud of.
                  </p>
                  <span class="text-orange">Michael Guzder, Principal</span>
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </>
  );
}
