import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./OccupationalSkills.css";
import img1 from "../../assets/img/ocu/1.jpg"
import img2 from "../../assets/img/ocu/2.jpg"
import img3 from "../../assets/img/ocu/3.jpg"
import img4 from "../../assets/img/ocu/4.jpg"
import { Helmet } from "react-helmet";
export default function OccupationalSkills() {
  return (
    <>
    <Helmet>
        <title>
        Occupational Skills | Manzil Center -Special Families Support
        </title>
        <meta
          name="description"
          content="Occupational Training Programs: Industry-Relevant Curriculum, Hands-On Learning, Career-Focused Support, Short-Term and Flexible, Diverse Specializations."
        />
        <meta name="keywords" content="Manzil center, Special Family Support, Occupational skill, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />
        <link rel="canonical" href="https://www.manzil.ae/occupational-skills" />
      <meta property="og:title" content="Occupational Skills | Manzil Center -Special Families Support" />
        <meta property="og:description" content="Occupational Training Programs: Industry-Relevant Curriculum, Hands-On Learning, Career-Focused Support, Short-Term and Flexible, Diverse Specializations." />
        <meta property="og:url" content="https://www.manzil.ae/occupational-skills" />
        <meta property="og:image" content="https://www.manzil.ae/static/media/4.1c7a577449de11c661b5.jpg" />
        <meta name="twitter:title" content="Occupational Skills | Manzil Center -Special Families Support" />
        <meta name="twitter:description" content="Occupational Training Programs: Industry-Relevant Curriculum, Hands-On Learning, Career-Focused Support, Short-Term and Flexible, Diverse Specializations." />
        <meta name="twitter:image" content="https://www.manzil.ae/static/media/4.1c7a577449de11c661b5.jpg" />
      </Helmet>
       <NavBar />
      <div className="page-title-area occu-inner">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Occupational Skills</h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Container className="mt-4 pt-2">
        <Row>
          <Col lg={12} className="text-center mb-4">
            <div className="main-heading">
              {/* <span className="center">Occupational Skills Training</span> */}
              <p className="mb-0">
                We are committed to empowering all learners with the essential
                skills and knowledge to thrive in their chosen careers.{" "}
              {/* </p>
              <p className="mb-0"> */}
                Our focus is on providing practical, hands-on learning
                experiences that directly relate to the demands of various
                occupations and industries.
              {/* </p>
              <p className="mb-0"> */}
                The programs are designed to bridge the gap between education
                and employment, equipping participants with industry-relevant
                skills and practical experience by offering tailored programs to
                match their specific needs and interests.
              </p>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="d-flex flex-wrap h-100 occupational-img">
              <span></span>
              <img
                src={img1}
                className="img-fluid w-50 p-2" alt="Industry-Relevant Curriculum"></img>
              <img
                src={img2}
                className="img-fluid w-50 p-2" alt="Career-Focused Support"></img>
              <img
                src={img3}
                className="img-fluid w-50 p-2" alt="Diverse Specializations | Manzil"></img>
              <img
                src={img4}
                className="img-fluid w-50 p-2" alt="Hands-On Learning | Manzil"></img>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="main-heading">
              <span>Key Features of Our Training Programs:</span>
            </div>
            <div className="occ-card">
              <p className="oracle-partner-card">
                <b>Industry-Relevant Curriculum:</b> Content is carefully
                compiled to align with the demands of specific industries or
                professions and it is regularly updated to reflect the latest
                trends, technologies, and the best practices within the field,
                ensuring graduates are equipped with relevant and up-to-date
                skills. Active feedback taken from industry partners whom we are
                already working with to keep this as close to real-world
                scenarios.
              </p>
              <p className="oracle-partner-card">
                <b>Hands-On Learning:</b> Graduates are engaged in real-world
                simulations, exercises, and workplace experiences that allow
                them to apply their theoretical knowledge in authentic settings.
                This hands-on approach builds confidence and competence in their
                chosen field.
              </p>
              <p className="oracle-partner-card">
                <b>Career-Focused Support:</b> Our programs include career
                counselling, initial job placement assistance, and
                resume-building workshops. This comprehensive support helps
                individuals navigate the job market successfully and secure
                gainful employment.
              </p>
              <p className="oracle-partner-card">
                <b>Short-Term and Flexible:</b> Our programs are designed to be
                short-term and flexible, accommodating the requirments of the
                students. This format allows participants to acquire valuable
                skills without significant disruptions to their daily lives.
              </p>
              <p className="oracle-partner-card">
                <b>Diverse Specializations:</b> We cover a wide range of
                specializations. Students can choose the area that aligns with
                their interests and career aspirations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
