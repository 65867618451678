import Notiflix from "notiflix";

var urlString = "https://lmsdev.manzil.ae/API/";
// var urlString = "http://localhost:8054/API/";
//var urlString = "http://192.168.1.15:8054/API/";

const PostApiCall = {
	postRequest(userData, url) {
		return fetch(urlString + url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
			},
			body: JSON.stringify(userData),
		})
			.then((response) => {
				if (response.status == 401) {
					// localStorage.clear();
					// window.location.href = "/";
					Notiflix.Notify.failure("Session Expired. Login Again");
				} else {
					return response;
				}
			})
			.catch((error) => {
				//// console.log('request failed', error);
				return error;
			});
	},
};

export default PostApiCall;
