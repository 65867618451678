import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import NavBar from "../Navbar/Navbar";
import "./ContactUs.css";
import { IoLocationSharp, IoCallSharp, IoMail } from "react-icons/io5";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import EnquiryForm from "../../Pages/EnquiryForm";
export default function ContactSection() {
  return (
    <>
      <NavBar />
      <div className="page-title-area contact-inner">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Contact Us </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Container className="my-4 pt-2">
        <Row>
          <Col lg={12} className="text-center mb-4">
            <div className="main-heading">
              <span className="center">Get In Touch</span>
              {/* <p>Corporates</p> */}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="contact-us">
            <div className="mb-3">
              <div className="d-flex">
                <div className="contact-us-icon align-self-start">
                  <IoLocationSharp />
                </div>
                <div>
                  <h5 className="mb-1">
                    Manzil Dubai <br /> (Occupational Skills)
                  </h5>
                  <a
                    href="https://goo.gl/maps/mPwXm4Z4xjJJWUPx6"
                    target="_blank"
                  >
                    Office 505, Nastaran Building, jadaf Waterfront, Dubai
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-us-icon">
                  <IoCallSharp />
                </div>
                <div>
                  <a href="tel:+97145294037">+9714 529 4037</a>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center mt-4">
                <div className="contact-us-icon align-self-start">
                  <IoLocationSharp />
                </div>
                <div>
                  <h5 className="mb-1">
                    Manzil Sharjah <br /> (Child Skills Development)
                  </h5>
                  <a
                    href="https://goo.gl/maps/6v9VAgxYJMvwKP4v9"
                    target="_blank"
                  >
                    Office 201B, Twin Towers, Al Majaz 2, Sharjah
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div className="contact-us-icon">
                  <IoCallSharp />
                </div>
                <div>
                  <a href="tel:+97165347663">+971 6534 7663</a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-us-icon">
                  <IoMail />
                </div>
                <div>
                  <a href="mailto:management@manzil.ae">management@manzil.ae</a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="mt-4 mt-lg-0 contact-form ">
            {/* <div className="d-flex justify-content-between mb-3 px-3">
              <p className="mb-0">
                <Link to="/enquiryform" className="contact-us-link">Click here</Link> for student enquiry.
              </p>
              <p className="mb-0">Fill the form below for corporates:</p>
            </div> */}
            {/* <Form>
              <Col lg={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3">
                  <Form.Control type="text" placeholder="Name" />
                </FloatingLabel>
              </Col>
              <Col lg={12}>
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Email"
                  className="mb-3">
                  <Form.Control type="text" placeholder="Email" />
                </FloatingLabel>
              </Col>
              <Col lg={12}>
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Phone"
                  className="mb-3">
                  <Form.Control type="text" placeholder="Phone" />
                </FloatingLabel>
              </Col>

              <Col lg={12}>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Message"
                  className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </Col>
              <Col className="d-lg-grid justify-content-end align-items-center">
                <Button className="btn default-btn-orange border-0">
                  Submit
                </Button>
              </Col>
            </Form> */}
            <iframe
              src="https://globaltrendz.info/ManzilEnquiry/1027/enquiry"
              width="100%"
              height="340"
              frameborder="0"
            ></iframe>
          </Col>
          {/* <Col lg={12} >
            <a href="/enquiryform" className="contact-us-link">Click here</a> for student enquiry.
          </Col> */}
        </Row>
      </Container>

      <Footer />
    </>
  );
}
