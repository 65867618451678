import React from "react";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "../../src/helpers/PostApi";
import GetApiCall from "../../src/helpers/GetApi";
import NavBar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Fld_id: null,
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Gender: "",
      DOB: "",
      FatherName: "",
      MotherName: "",
      StudentDiagnostics: "",
      Email: "",
      Mobile: "",
      Country: "",
      CountryData: [],
      CountryId: null,
      PhoneCode: null,
      EnqDate: "",
      EnqTime: "",
      ProgrammeSelect: "",
      NumRegex: /^[0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      imagePreviewUrl:
        "https://www.adcproductdesign.com/wp-content/uploads/2018/02/Realize-Icon-Blue.png",
      ImageData: [],
      ProgrammeList: [],
      GenderData: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Others", value: "Others" },
      ],
      EnquiryNumber: null,
    };
  }
  onChangeGender(gender) {
    this.setState({
      Gender: gender.target.value,
    });
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "#2953a0",
    });
    Notiflix.Loading.dots("Please wait...");

    GetApiCall.getRequest("getcountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          console.log(obj.data);
          this.setState({
            CountryData: obj.data,
          });
        }
      })
    );

    PostApiCall.postRequest({ whereClause: "" }, "getprogrammaster").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              ProgrammeList: obj.data,
            });

            Notiflix.Loading.remove();
          }
        })
    );

    // const editparam = new URLSearchParams(this.props.location.search).get('edit');

    // if (editparam) {
    // 	PostApiCall.postRequest(
    // 		{
    // 			WhereClause: ''
    // 		},
    // 		"getenquiry"
    // 	).then((results) =>

    // 		results.json().then((obj) => {

    // 			if (results.status == 200 || results.status == 201) {
    // 				const obj1 = obj.data.find(val => val.FLD_ID == editparam)
    // 				console.log(obj1)
    // 				this.setState({
    // 					Fld_id: obj1.FLD_ID,
    // 					EnquiryNumber: obj1.FLD_ENQ_NO,
    // 					FirstName: obj1.FLD_FIRST_NAME,
    // 					MiddleName: obj1.FLD_MIDDLE_NAME,
    // 					LastName: obj1.FLD_LAST_NAME,
    // 					Gender: obj1.FLD_GENDER,
    // 					DOB: obj1.FLD_DOB,
    // 					FatherName: obj1.FLD_FATHER_NAME,
    // 					MotherName: obj1.FLD_MOTHER_NAME,
    // 					Email: obj1.FLD_EMAIL_ID,
    // 					Mobile: obj1.FLD_MOBILE_NUMBER,
    // 					ProgrammeSelect: obj1.FLD_PROGRAM_ENQUIRY,
    // 					EnqTime: obj1.FLD_ENQUIRY_TIME,
    // 					Country: obj1.FLD_COUNTRY,
    // 					EnqDate: moment(obj1.FLD_ENQUIRY_DATE).format("DD/MM/YYYY"),
    // 					StudentDiagnostics: obj1.FLD_STUDENT_DIAGNOSTICS,
    // 					PhoneCode: obj1.FLD_COUNTRY_CODE
    // 				});
    // 			}
    // 		})
    // 	)
    // }
  }

  SaveRegistration() {
    if (this.state.EnqDate != "") {
      if (this.state.EnqTime != "") {
        if (this.state.ProgrammeSelect != "") {
          if (this.state.FirstName != "") {
            if (this.state.LastName != "") {
              if (this.state.Gender !== "") {
                if (this.state.DOB !== "") {
                  if (this.state.Email !== "") {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                      this.onPostRegistration();
                    } else {
                      Notiflix.Notify.failure(
                        "Please enter valid email address."
                      );
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter Email.");
                  }
                } else {
                  Notiflix.Notify.failure("Please enter Date of Birth.");
                }
              } else {
                Notiflix.Notify.failure("Please select Gender.");
              }
            } else {
              Notiflix.Notify.failure("Please enter your LastName.");
            }
          } else {
            Notiflix.Notify.failure("Please enter your Firstname.");
          }
        } else {
          Notiflix.Notify.failure("Please select Programme.");
        }
      } else {
        Notiflix.Notify.failure("Please enter Enquiry Time.");
      }
    } else {
      Notiflix.Notify.failure("Please enter Enquiry Date.");
    }
  }

  onPostRegistration = () => {
    Notiflix.Loading.dots("Please wait...");

    const LoginUser = JSON.parse(localStorage.getItem("LoginData"));

    PostApiCall.postRequest(
      {
        id: this.state.Fld_id,
        firstname: this.state.FirstName,
        middlename: this.state.MiddleName,
        lastname: this.state.LastName,
        gender: this.state.Gender,
        dob: this.state.DOB,
        fathername: this.state.FatherName,
        mothername: this.state.MotherName,
        emailid: this.state.Email,
        mobilenumber: this.state.Mobile,
        studentdiagnostics: this.state.StudentDiagnostics,
        programenquiry: this.state.ProgrammeSelect,
        enquirytime: this.state.EnqTime,
        country: this.state.Country,
        enquirydate: this.state.EnqDate,
        countrycode: this.state.PhoneCode,
        // userid: LoginUser[0].fld_userid // from login detail
      },
      "enquiry"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Thank you, our team will contact you shortly.");

          window.location.href = "/";
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  render() {
    return (
      <div className="container-fluid px-0">
        <NavBar />
        <div className="page-title-area">
          <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
            <div className="page-title-content">
              <ul>
                <li>
                  <h1> Enquiry Form </h1>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="p-3 bg-white rounded-3 EnquiryFormSpace mt-5 container">
          <form class="row justify-content-between enquiryform">
            <div class="form-group col-md-3 mb-3">
              <label for="Registration_Id">Enquiry No.</label>
              <input
                id="Registration_Id"
                type="text"
                class="form-control"
                value={this.state.EnquiryNumber}
                disabled
              />
            </div>

            <div class="form-group col-md-3  mb-3">
              <label for="validationCustom01">
                Enquiry Date <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                placeholder="dd-mm-yyyy"
                min={moment().format("DD/MM/YYYY")}
                value={this.state.EnqDate}
                onChange={(text) =>
                  this.setState({ EnqDate: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-3 mb-3">
              <label for="validationCustom01">
                Enquiry Time <span class="text-danger">*</span>
              </label>
              <input
                type="time"
                class="form-control"
                value={this.state.EnqTime}
                onChange={(text) =>
                  this.setState({ EnqTime: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-3 mb-3">
              <label for="exampleInputEmail1">
                Program Enquiry <span class="text-danger">*</span>
              </label>
              <select
                onChange={(e) =>
                  this.setState({ ProgrammeSelect: e.target.value })
                }
                class="form-select"
              >
                <option>Please Select..</option>
                {this.state.ProgrammeList.map((item) => (
                  <option
                    value={item.FLD_PROGRAM_ID}
                    selected={this.state.ProgrammeSelect == item.FLD_PROGRAM_ID}
                  >
                    {item.FLD_PROGRAM_NAME}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group col-md-4 mb-3">
              <label for="First_Name">
                First Name <span class="text-danger">*</span>
              </label>
              <input
                id="First_Name"
                type="text"
                class="form-control"
                value={this.state.FirstName}
                onChange={(text) =>
                  this.setState({ FirstName: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-4 mb-3">
              <label for="Middle_Name">Middle Name </label>
              <input
                id="Middle_Name"
                type="text"
                class="form-control"
                value={this.state.MiddleName}
                onChange={(text) =>
                  this.setState({ MiddleName: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-4 mb-3">
              <label for="Last_Name">
                Last Name <span class="text-danger">*</span>
              </label>
              <input
                id="Last_Name"
                type="text"
                class="form-control"
                value={this.state.LastName}
                onChange={(text) =>
                  this.setState({ LastName: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-4  mb-3">
              <label for="Gender">
                Gender <span class="text-danger">*</span>{" "}
              </label>
              <select
                className="form-select"
                id="Gender"
                value={this.state.Gender}
                onChange={(text) => {
                  this.setState({ Gender: text.target.value });
                }}
              >
                <option value=""> Gender</option>
                {this.state.GenderData.map((data, i) => (
                  <option
                    value={data.value}
                    selected={this.state.Gender == data.value}
                  >
                    {data.label}
                  </option>
                ))}
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label for="dob">
                Date of Birth <span class="text-danger">*</span>{" "}
              </label>
              <input
                id="dob"
                type="date"
                class="form-control"
                placeholder="dd-mm-yyyy"
                min="1980-01-01"
                max={moment().format("DD/MM/YYYY")}
                value={this.state.DOB}
                onChange={(text) => this.setState({ DOB: text.target.value })}
              />
            </div>
            <div class="form-group col-md-4 mb-3">
              <label for="country">Country of Residence</label>
              <select
                class="form-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                onChange={(e) =>
                  this.setState({
                    Country: e.target.value.split(",")[0],
                    PhoneCode: e.target.value.split(",")[1],
                  })
                }
              >
                {this.state.CountryData.map((data) => (
                  <option
                    value={`${data.label},${data.Phone_Code}`}
                    selected={data.label === this.state.Country}
                  >
                    {data.label}
                  </option>
                ))}
              </select>
            </div>

            <div class="form-group col-md-6 mb-3">
              <label
                for="Email"
                style={{ fontWeight: "400", fontSize: "15px" }}
              >
                Parent's Email <span class="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                class="form-control"
                value={this.state.Email}
                id="Email"
                onChange={(email) =>
                  this.setState({ Email: email.target.value })
                }
              ></input>
            </div>

            <div class="form-group col-md-6 mb-3">
              <label for="Number">Phone Number (Without Country Code) </label>
              <br />

              <div class="d-flex align-items-center">
                <select
                  class="form-select my-1 w-25"
                  id="inlineFormCustomSelectPref"
                  onChange={(e) => this.setState({ PhoneCode: e.target.value })}
                >
                  {this.state.CountryData.map((data) => {
                    return (
                      <option
                        value={data.Phone_Code}
                        selected={data.Phone_Code == this.state.PhoneCode}
                      >
                        {data.Phone_Code_display.length > 23
                          ? `${data.Phone_Code_display.slice(0, 20)}...`
                          : data.Phone_Code_display}
                      </option>
                    );
                  })}
                </select>

                <input
                  type="text"
                  placeholder="Phone Number"
                  class="form-control "
                  value={this.state.Mobile}
                  style={{ paddingLeft: "10px" }}
                  id="Number"
                  onChange={(mobile) => {
                    if (this.state.NumRegex.test(mobile.target.value)) {
                      this.setState({ Mobile: mobile.target.value });
                    }
                  }}
                />
              </div>
            </div>

            <div class="form-group col-md-6 mb-3">
              <label for="father_name">Father's Name</label>
              <input
                type="text"
                class="form-control"
                id="father_name"
                value={this.state.FatherName}
                onChange={(text) =>
                  this.setState({ FatherName: text.target.value })
                }
              />
            </div>

            <div class="form-group col-md-6 mb-3">
              <label for="Mother_name">Mother's Name</label>
              <input
                type="text"
                class="form-control"
                id="Mother_name"
                value={this.state.MotherName}
                onChange={(text) =>
                  this.setState({ MotherName: text.target.value })
                }
              />
            </div>

            <div class="form-group col-lg-12 col-md-8 mb-2">
              <label>Students Diagnostics</label>
              <textarea
                class="form-control"
                onChange={(text) =>
                  this.setState({ StudentDiagnostics: text.target.value })
                }
                value={this.state.StudentDiagnostics}
                aria-label="With textarea"
              ></textarea>
            </div>

            {/* <div class=" hover_effect d-flex mt-3 bg-white justify-content-between align-items-center px-4 border-bottom py-2 rounded-3">

                                <div class="d-flex align-items-center">
                                    <button type="button" class="btn btn-primary rounded btn-lg btn-block my-4 ms-3 " onClick={this.SaveRegistration.bind(this)}> Submit Enquiry</button>

                                </div>

                            </div> */}
          </form>

          <div className="col-md-12 text-end mt-4 mb-5">
            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  class="btn btn-primary rounded btn-lg btn-block"
                  onClick={this.SaveRegistration.bind(this)}
                >
                  {" "}
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EnquiryForm;
