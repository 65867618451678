import { useState } from "react";
import {
  Col,
  Container,
  Modal,
  Row,
  Button,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import NavBar from "../Navbar/Navbar";
import "./ChildDevelopment.css";
import Footer from "../Footer/Footer";
import YouTube, { YouTubeProps } from "react-youtube";
import Chart from "../../assets/img/Applied-Arts2.png";
import { Helmet } from "react-helmet";
export default function ChildDevelopment() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const opts = {
    height: "590",
    width: "100%",
    playerVars: {
      autoplay: true,
    },
  };
  return (
    <>
      <Helmet>
        <title>
          Child Skills Development | Manzil Center -Special Families Support
        </title>
        <meta
          name="description"
          content="Manzil students who are enrolled in the training program have access to the following services: Numeracy Enhancement, Communication Skills, etc."
        />
        <meta
          name="keywords"
          content="Manzil center, Special Family Support, Child skills development, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah"
        />
        <link
          rel="canonical"
          href="https://www.manzil.ae/child-skills-development"
        />
        <meta
          property="og:title"
          content="Child Skills Development | Manzil Center -Special Families Support"
        />
        <meta
          property="og:description"
          content="Manzil students who are enrolled in the training program have access to the following services: Numeracy Enhancement, Communication Skills, etc."
        />
        <meta
          property="og:url"
          content="https://www.manzil.ae/child-skills-development"
        />
        <meta
          property="og:image"
          content="https://www.manzil.ae/static/media/Applied-Arts2.3e5603f92264d116fb7b.png"
        />
        <meta
          name="twitter:title"
          content="Child Skills Development | Manzil Center -Special Families Support"
        />
        <meta
          name="twitter:description"
          content="Manzil students who are enrolled in the training program have access to the following services: Numeracy Enhancement, Communication Skills, etc."
        />
        <meta
          name="twitter:image"
          content="https://www.manzil.ae/static/media/Applied-Arts2.3e5603f92264d116fb7b.png"
        />
      </Helmet>
      <NavBar />
      <div className="page-title-area child-inner">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Child Skills Development</h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Container className="mt-4 pt-2">

        <Row>
          <Col lg={12} className="text-center">
            <div className="main-heading">
              {/* <span className="center">Child Skill Development</span> */}
              <p>
                Every student at Manzil follows an individual learning plan
                (ILP). The training program follows a holistic approach
                comprising development of emotional intelligence, academic,
                self-help, cognitive, social, and motor skills primarily through
                the medium of art
              </p>
            </div>
          </Col>
          {/* <Col lg={12}>
            <div className="main-heading mt-5">
              <span>Manzil Art Collection</span>

              <p>
                Manzil Art Collection displayed in prestigious art studio and
                hotels in Dubai
              </p>
            </div>
          </Col> */}
          <Col lg={12}>
            <div className="main-heading text-center mt-5">
              <span className="center">Services</span>

              <p>
                Manzil students who are enrolled in the training program have
                access to the following services :
              </p>
            </div>

            <div className="text-center">
              <img src={Chart} className="img-fluid" alt="Numeracy Enhancement , Communication Skills , Digital Literacy , Manzil" />
            </div>
          </Col>
          <Col lg={12}>
            {/* <video
              src={BannerVideo}
              poster={videothum}
              autoPlay
              muted
              // playsInline
              loop
              className="w-100 h-100">
              <source src={BannerVideo} type="video/mp4" /> 
            </video> */}

            {/* <video
              src={BannerVideo}
              poster={videothum}
              autoPlay="true"
              muted
              loop
              className="w-100 h-100"
            /> */}
            {/* <ModalVideo
              channel="youtube"
              youtube={{ autoplay: true }}
              isOpen={isOpen}
              videoId="HgLniIYaxV0"
              onClose={() => setOpen(false)}
            />
            <button className="btn-primary" onClick={() => setOpen(true)}>
              VIEW DEMO
            </button> */}
            <YouTube
              videoId="HgLniIYaxV0"
              opts={opts}
              onReady={onPlayerReady}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
      {/* <Button variant="primary" onClick={handleShow} className='enquiry-btn default-btn-orange border-0 px-3'>
        Enquiry
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="Name" />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Email"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="Email" />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Phone"
                className="mb-3"
              >
                <Form.Control type="text" placeholder="Phone" />
              </FloatingLabel>
            </Col>

            <Col lg={12}>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Message"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Col>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className="default-btn-orange border-0">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
