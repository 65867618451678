import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/img/manzil-logo.png";
import "./Navbar.css";
import { BsTwitter, BsWhatsapp } from "react-icons/bs";
import { MdCall } from "react-icons/md";
import {
  BiLogoFacebook,
  BiLogoInstagramAlt,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
export default function NavBar() {
  return (
    <>
      <Navbar expand="lg" className="shadow-sm inner-nav sticky-top bg-white">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo} alt="Manzil" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link
              href="/our-story"
              className={({ isActive }) => (isActive ? "active" : "inactive")}>
              Our Story
            </Nav.Link>
            <Nav.Link href="/child-skills-development">
              Child Skills Development
            </Nav.Link>
            <Nav.Link href="/occupational-skills">
              Occupational Skills{" "}
            </Nav.Link>
            <Nav.Link href="/technology-innovation">
              Technology & Innovation
            </Nav.Link>
            <Nav.Link href="/consultancy">Consultancy</Nav.Link>
            <Nav.Link
              href="/contact-us"
              className="default-btn default-btn-orange me-0">
              Contact Us
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="ls-bottom fixed-btns fixed-btns-left">
        <ul className="flex-column ps-1">
          <li>
            <a
              href="https://wa.me/+971509817767?text=Hello Manzil"
              className="w-icon"
              target="_blank">
              <BsWhatsapp />
            </a>
          </li>
          {/* <li>
            <a href="tel:97145294037" target="_blank" className="call-btn">
              <MdCall />
            </a>
          </li> */}
          {/* <li>
            <a
              href="https://www.facebook.com/ManzilCentre/?ref=hl"
              target="_blank">
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/manzil_center/" target="_blank">
              <BiLogoInstagramAlt />
            </a>
          </li>
          <li>
            <a href="https://in.linkedin.com/company/manzil" target="_blank">
              <BiLogoLinkedin />
            </a>
          </li> */}
          {/* <li>
            <a
              href="https://mobile.twitter.com/friendsofmanzil"
              target="_blank">
              <BsTwitter />
            </a>
          </li> */}
        </ul>
      </div>
      {/* <div className="ls-bottom fixed-btns">
        <ul className="flex-column">
          <li>
            <a href="https://wa.me/+971509817767" className="w-icon" target="_blank">
              <BsWhatsapp />
            </a>
          </li>
          <li>
            <a href="tel:97145294037" target="_blank">
              <MdCall />
            </a>
          </li>
        </ul>
      </div> */}
    </>
  );
}
