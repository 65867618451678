import logo from "../../assets/img/manzil-logo.png";
import { BsFacebook, BsLinkedin, BsTwitter, BsWhatsapp } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoInstagramAlt,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import "./LeftSection.css";
export default function LeftSection() {
  return (
    <>
      <div className="left-section ps-0">
        <div className="mt-4">
          <a href="/" className="ps-1 d-inline w-auto">
            <img src={logo} className="img-fluid" alt="Manzil"/>
          </a>
        </div>
        <div className="ls-content">
          <h1>Our Journey</h1>
          <p className="mb-4">
            Manzil has been at the forefront of Diversity, Equity & Inclusion
            (DEI) advocacy & training for 2 decades. We have worked with 30+
            Educational Institutions, 275+ corporates & 10,000+ individuals since 2005, and have
            won several international awards such as the UN Zero Project,
            Arabian Business Social Service Provider & Princess Haya Outstanding
            Director General Award
          </p>
          <a href="/our-story" className="default-btn bg-white text-blue">
            Read More
          </a>
        </div>
        <div className="d-flex mt-3 mt-lg-0 align-items-start justify-content-center justify-content-lg-between ls-bottom pe-4 me-2 ps-1">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/ManzilCentre/?ref=hl"
                target="_blank" className="facebook-icon">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/manzil_center/"
                target="_blank" className="insta-icon">
                <BiLogoInstagramAlt />
              </a>
            </li>
            <li>
              <a href="https://in.linkedin.com/company/manzil" target="_blank" className="linkedin-icon">
                <BiLogoLinkedin />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/+971509817767"
                className="w-icon"
                target="_blank">
                <BsWhatsapp />
              </a>
            </li>
            {/* <li>
              <a
                href="https://mobile.twitter.com/friendsofmanzil"
                target="_blank">
                <BsTwitter />
              </a>
            </li> */}
          </ul>
          <div className="d-none d-lg-flex align-items-start">
            {/* <ul>
              <li>
                <a href="https://wa.me/+971509817767" className="w-icon" target="_blank">
                  <BsWhatsapp />
                </a>
              </li>
              <li>
                <a href="tel:97145294037" target="_blank">
                  <MdCall />
                </a>
              </li> 
            </ul> */}
            <a href="/contact-us" className="default-btn">
              Contact Us
            </a>
          </div>
        </div>
        <div className=" d-block w-100 d-lg-none text-center">
        <a href="/contact-us" className="default-btn w-75">
          Contact Us
        </a>
        </div>
      </div>
    </>
  );
}
