import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Story from "./Pages/Story";
import ChildDevelopment from "./Components/ChildDevelopment/ChildDevelopment";
import OccupationalSkills from "./Components/OccupationalSkills/OccupationalSkills";
import TechnologyInnovation from "./Components/TechnologyInnovation/TechnologyInnovation";
import Consultancy from "./Components/Consultancy/Consultancy";
import Contact from "./Pages/Contact";
import { Navigate } from "react-router-dom";
import EnquiryForm from "./Pages/EnquiryForm";
import { useEffect } from "react";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "./helpers/PostApi";
import Demo from "./Pages/demo";

function App() {

  useEffect(() => {
    GetToken() // token generation
  }, [])

  function GetToken() {
    PostApiCall.postRequest(
      {
        email: "admin@globaltrendz.com",
        password: "",
        action: 'Login',
        actiondate: moment().format('lll'),
      }, "addwebistelogin").then((results) =>

        results.json().then(obj => {
          if ((results.status == 200 || results.status == 201)) {

            localStorage.setItem('access', JSON.stringify(obj.token))
            // localStorage.setItem('LoginData', JSON.stringify(obj.data))
            // console.log(obj)
            // window.location.href = `/dashboard`;

          }
          else {
            Notiflix.Notify.failure(obj.data)
          }
        })
      )

  }


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/admissions' element={<Navigate to="/child-skills-development" />} />
          <Route path='/voluntering.aspx' element={<Navigate to="/consultancy" />} />
          <Route path='/news' element={<Navigate to="/our-story" />} />
          <Route path='/events' element={<Navigate to="/our-story" />} />
          <Route path='/photo' element={<Navigate to="/our-story" />} />
          <Route path='/video' element={<Navigate to="/our-story" />} />
          <Route path='/autism' element={<Navigate to="/child-skills-development" />} />
          <Route path='/careers' element={<Navigate to="/contact-us" />} />
          <Route path='/leads' element={<Navigate to="/contact-us" />} />
          <Route path='/mythsandfactsofautism' element={<Navigate to="/" />} />


          <Route exact path="/our-story" element={<Story />} />
          <Route exact path="/child-skills-development" element={<ChildDevelopment />} />
          <Route exact path="/occupational-skills" element={<OccupationalSkills />} />
          <Route exact path="/technology-innovation" element={<TechnologyInnovation />} />
          <Route exact path="/consultancy" element={<Consultancy />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/demo" element={<Demo />} />
          <Route exact path="/enquiryform" element={<EnquiryForm />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
