import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { BsWhatsapp } from "react-icons/bs";
import { MdCall } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoInstagramAlt, BiLogoLinkedin } from "react-icons/bi";
import "./Footer.css"
export default function Footer() {
  return (
    <>
      <Container className="border-top footer" fluid>
        <Row className="py-3 justify-content-lg-between align-content-center">
          <Col lg={2} className="mb-5 mb-lg-0 justify-content-center justify-content-lg-start d-flex align-items-center order-3 order-lg-1">
            Design{" "}
            <a href="http://globaltrendz.com/" target="_blank" className="text-orange ps-1">
              Global Trendz
            </a>
          </Col>
          <Col lg={5} className="ls-bottom footer-links order-1 order-lg-2">
          <ul className="mb-2 mb-lg-0 pe-lg-4 justify-content-center justify-content-lg-end mb-0">
          
          <li>
            <a
              href="https://www.facebook.com/ManzilCentre/?ref=hl"
              target="_blank" className="facebook-icon">
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/manzil_center/" target="_blank" className="insta-icon">
              <BiLogoInstagramAlt />
            </a>
          </li>
          <li>
            <a href="https://in.linkedin.com/company/manzil" target="_blank" className="linkedin-icon">
              <BiLogoLinkedin />
            </a>
          </li>
          {/* <li>
            <a
              href="https://mobile.twitter.com/friendsofmanzil"
              target="_blank">
              <BsTwitter />
            </a>
          </li> */}
        </ul>
          </Col>
          <Col lg={5} className="mb-2 mb-lg-0 justify-content-center justify-content-lg-end d-flex align-items-center  order-2 order-lg-3">
            &copy; {moment().format("YYYY")}  Manzil Center. All Rights
            Reserved.
          </Col>
        </Row>
      </Container>
    </>
  );
}
