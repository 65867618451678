import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Navbar/Navbar";
import "./Consultancy.css";
import EmiratesNBD from "../../assets/logos/Emirates-NBD.png";
import ApparelGroup from "../../assets/logos/Apparel-Group.png";
import Tanfeeth from "../../assets/logos/Tanfeeth.png";
import HyattRegency from "../../assets/logos/Hyatt-Regency.png";
import JumerahCreeckside from "../../assets/logos/Jumerah-Creeckside.png";
import HSBC from "../../assets/logos/HSBC.png";
import ClydeCo from "../../assets/logos/Clyde-Co.png";
import Rotana from "../../assets/logos/Rotana.png";
import Transworld from "../../assets/logos/Transworld.png";
import Emrill from "../../assets/logos/Emrill.png";

import TMS from "../../assets/logos/TMS.png";
import GEMS from "../../assets/logos/GEMS.png";
import DubaiScholars from "../../assets/logos/Dubai-scholars.png";
import AmityUniversity from "../../assets/logos/Amity-University.png";
import MiddlesexUni from "../../assets/logos/Middlesex-Uni.png";
import Repton from "../../assets/logos/Repton.png";
import Footer from "../Footer/Footer";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";
export default function Consultancy() {
  return (
    <>
      <Helmet>
        <title>
          Consultancy | Manzil Center -Special Families Support | Sharjah
        </title>
        <meta
          name="description"
          content="Manzi Center has worked with over 275 companies spanning DEI assessments, DEI strategy & policy compliance, and employment-related services."
        />
        <meta name="keywords" content="Manzil center, Special Family Support, Educational and Corporate consultancy, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />
        <link rel="canonical" href="https://www.manzil.ae/consultancy" />
        <meta
          property="og:title"
          content="Consultancy | Manzil Center -Special Families Support | Sharjah"
        />
        <meta
          property="og:description"
          content="Manzi Center has worked with over 275 companies spanning DEI assessments, DEI strategy & policy compliance, and employment-related services."
        />
        <meta property="og:url" content="https://www.manzil.ae/consultancy" />
        <meta
          name="twitter:title"
          content="Consultancy | Manzil Center -Special Families Support | Sharjah"
        />
        <meta
          name="twitter:description"
          content="Manzi Center has worked with over 275 companies spanning DEI assessments, DEI strategy & policy compliance, and employment-related services."
        />
      </Helmet>
      <NavBar />
      <div className="page-title-area consul-inner">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Consultancy </h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Container className="mt-4 pt-2">
        <Row>
          <Col lg={12} className="text-center">
            <div className="main-heading">
              {/* <span className="center">Consultancy</span> */}
              <p>
                We work closely with corporate as well as educational
                institutions across different areas. Some of our key initiatives
                and clients are shared below:
              </p>
            </div>
          </Col>
          <Col lg={12}>
            <div className="main-heading">
              <span>Corporate Consultancy</span>
              <p>
                We have worked with over 275 companies spanning DEI assessments,
                DEI strategy & policy compliance, employment related services
                (Internships, job matching, job coaching, WFH training, staff
                training etc) and well-being / stress management workshops
              </p>
            </div>
            {/* <Row> */}
            <Swiper
              slidesPerView={1}
              loop={true}
              spaceBetween={10}
              pagination={false}
              navigation={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: true,
              // }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[Navigation, Pagination]}
              className="mySwiper pt-3 client-swiper">
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={EmiratesNBD}
                    className="img-fluid"
                    alt="Emirates NBD"
                  />
                  <p>Emirates NBD</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={ApparelGroup}
                    className="img-fluid"
                    alt="Apparel Group"
                  />
                  <p>Apparel Group</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={Tanfeeth} className="img-fluid" alt="Tanfeeth" />
                  <p>Tanfeeth</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={HyattRegency}
                    className="img-fluid"
                    alt="Hyatt Regency"
                  />
                  <p>Hyatt Regency</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={JumerahCreeckside}
                    className="img-fluid"
                    alt="Jumerah Creeckside"
                  />
                  <p>Jumerah Creeckside</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={HSBC} className="img-fluid" alt="HSBC" />
                  <p>HSBC</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={ClydeCo} className="img-fluid" alt="Clyde & Co" />
                  <p>Clyde & Co</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={Rotana} className="img-fluid" alt="Rotana" />
                  <p>Rotana</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={Transworld}
                    className="img-fluid"
                    alt="Transworld"
                  />
                  <p>Transworld</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={Emrill} className="img-fluid" alt="Emrill" />
                  <p>Emrill</p>
                </div>
              </SwiperSlide>
            </Swiper>
            {/* </Row> */}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="main-heading my-4">
              <span>Education Consultancy</span>
              <p>
                Baseline Assessments & SEND Program review, UDL for classroom
                learning, Mental health & Stress management, LSA training &
                Teacher capacity building workshops are some of the successful
                initiatives undertaken with about 15 institutions!
              </p>
            </div>
          </Col>
          {/* <Col lg={4} className="mb-5">
            <div className="education-card">
              <h5>Educational Services & Support</h5>
              <p>
                Baseline Assessments & Inclusion Universal Design for Learning
                in classroom Student Performance & Behaviour Management Systems
                Gifted Education Well-being & Mental Health
              </p>
            </div>
          </Col>
          <Col lg={4} className="mb-5">
            <div className="education-card">
              <h5>Professional Development</h5>
              <p>
                Manzil partners with educational leaders and classroom
                professionals to help ensure their success and advancement
                Leadership Team Building Teacher capacity Building Workshops
              </p>
            </div>
          </Col>
          <Col lg={4} className="mb-5">
            <div className="education-card">
              <h5>Strategy & Engagement</h5>
              <p>
                Manzil offers specific programs and services to support schools,
                institutions, and universities, not limited to: SEND Program
                Review & Analysis Policy Review & Impact Measurement Assessment
                Program for Systemic Improvement Leadership Coaching Structured
                Parent Engagement Community Outreach
              </p>
            </div>
          </Col> */}

          <Col lg={12}>
            <Swiper
              slidesPerView={1}
              loop={true}
              spaceBetween={10}
              pagination={false}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 50,
                },
              }}
              modules={[Navigation, Pagination]}
              className="mySwiper pt-3 client-swiper">
              <SwiperSlide>
                <div className="client-card">
                  <img src={TMS} className="img-fluid" alt="TMS" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={GEMS} className="img-fluid" alt="GEMS" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={DubaiScholars}
                    className="img-fluid"
                    alt="Dubai Scholars"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={AmityUniversity}
                    className="img-fluid"
                    alt="Amity University"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img
                    src={MiddlesexUni}
                    className="img-fluid"
                    alt="Middlesex Uni"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="client-card">
                  <img src={Repton} className="img-fluid" alt="Repton" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
