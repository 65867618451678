import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./TechnologyInnovation.css";
import techImg from "../../assets/img/tech.jpg";
import { Helmet } from "react-helmet";
export default function TechnologyInnovation() {
  return (
    <>
      <Helmet>
        <title>
          Technology & Innovation | Manzil Center -Special Families Support
        </title>
        <meta
          name="description"
          content="Technology & Innovation: Immersive Technology, AI-based Assessments & Training, LMS (Learning Management System) Manzil Center"
        />
        <meta name="keywords" content="Manzil center, Special Family Support, Technology and Innovation, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />
        <link
          rel="canonical"
          href="https://www.manzil.ae/technology-innovation"
        />
        <meta
          property="og:title"
          content="Technology & Innovation | Manzil Center -Special Families Support"
        />
        <meta
          property="og:description"
          content="Technology & Innovation: Immersive Technology, AI-based Assessments & Training, LMS (Learning Management System) Manzil Center"
        />
        <meta
          property="og:url"
          content="https://www.manzil.ae/technology-innovation"
        />
        <meta
          property="og:image"
          content="https://www.manzil.ae/static/media/tech.c36c4b39166f77bc379f.jpg"
        />
        <meta
          name="twitter:title"
          content="Technology & Innovation | Manzil Center -Special Families Support"
        />
        <meta
          name="twitter:description"
          content="Technology & Innovation: Immersive Technology, AI-based Assessments & Training, LMS (Learning Management System) Manzil Center"
        />
        <meta
          name="twitter:image"
          content="https://www.manzil.ae/static/media/tech.c36c4b39166f77bc379f.jpg"
        />
      </Helmet>
      <NavBar />
      <div className="page-title-area tech-inner">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Technology & Innovation</h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <Container className="my-4 pt-5">
        <Row>
          <Col lg={5}>
            <div class="tp-about-us__main-thumb">
              <img
                className="img-fluid"
                src="https://wphix.com/wp/poorex/wp-content/uploads/2023/07/about-us-1-1.jpg"
                alt=""
              />
              <div class="tp-about-us__thumb-sm">
                <img
                  className="img-fluid"
                  src="https://wphix.com/wp/poorex/wp-content/uploads/2023/07/about-us-1-2.jpg"
                  alt=""
                />
              </div>
              <div class="tp-about-us__thumb-border"></div>
            </div>
          </Col>
          <Col lg={7} className="ps-lg-5">
            <div className="ps-lg-5">
              <div className="main-heading">
                <h2 className="mb-4 m-0">Technology and Innovation</h2>
              </div>
              <p>
                Manzil has always been at the forefront of innovation and
                adopting best practices to impart knowledge.
              </p>
              <p>
                Working closely with a leading UAE AR/VR startup, we have
                created the region’s first immersive training experience for the
                hospitality sector. This will be accompanied by a research paper
                delving deeper into what works - and what doesn’t- especially
                for PwDs.
              </p>
              <p>
                We are also working on a medium term programme using NLP and
                machine learning to assess individuals, create training
                programmes and then match them to existing job opportunities.
                This could become a game changer in the way people are connected
                with the right opportunity!
              </p>
              <p>
                Lastly, we have also launched our online Learning Management
                System which has training content from Basic to Advanced levels
                covering a wide range of non academic subjects.
              </p>
            </div>
          </Col>
        </Row>
      </Container> */}

      <Container className="my-4 pb-lg-5">
        <Row>
          <Col lg={12}>
            <div className="main-heading text-center mb-5">
              {/* <span className="center">Technology Driving Services</span> */}
              <p>
                Manzil has always been at the forefront of innovation and
                adopting best practices to acquire and impart knowledge. Here
                are some of the things we are doing (and working on to launch
                soon!) especially when it comes to the use of technology.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <img src={techImg} className="img-fluid" alt="Immersive Technology , AI based Assessments & Training , LMS | Manzil"/>
          </Col>
          <Col lg={6}>
            <Row>
              {/* <Col md={6} className="mb-4">
                <div className="oracle-partner-card">
                  <h3> Technology driving Services</h3>
                  <p>
                    Manzil has always been at the forefront of innovation and
                    adopting best practices to acquire and impart knowledge.
                    Here are some of the things we are doing (and working on to
                    launch soon!) especially when it comes to the use of
                    technology.
                  </p>
                </div>
              </Col> */}
              <Col md={12} className="mb-4">
                <div className="oracle-partner-card">
                  <h3>Immersive Technology</h3>
                  <p>
                    Working closely with a leading UAE AR/VR startup, we have
                    created the region’s first immersive training experience for
                    the hospitality sector. This will be accompanied by a
                    research paper delving deeper into what works and what
                    doesn’t for PwDs.
                  </p>
                </div>
              </Col>
              <Col md={12} className="mb-4">
                <div className="oracle-partner-card">
                  <h3> AI based Assessments & Training</h3>
                  <p>
                    We are also working on a medium term programme using NLP and
                    machine learning to assess individuals, create training
                    programmes and then match them to existing job
                    opportunities. This could become a game changer in the way
                    people are connected with the right opportunity!
                  </p>
                </div>
              </Col>
              <Col md={12} className="mb-4">
                <div className="oracle-partner-card">
                  <h3>LMS</h3>
                  <p>
                    We have launched our online Learning Management System which
                    has training content from Basic to Advanced levels covering
                    a wide range of non academic subjects.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
